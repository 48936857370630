import AppInput from '@theme/components/atom/AppInput'
import AppInputsParentMixin from '@/mixins/AppInputsParentMixin'
import InjectValidator from '~/app/InjectValidator'
import ProvideValidator from '~/app/ProvideValidator'
import _ from 'lodash'

export default {
  mixins: [AppInputsParentMixin, InjectValidator, ProvideValidator],
  components: {
    AppInput,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    inputId: {
      type: String,
      default: 'PhoneInput',
    },
    inputClass: {
      type: String,
      default: '',
    },
    messageStyleAbsolute: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    showValidIndicator: {
      type: Boolean,
      default: true,
    },
    validation: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const selectedCountry =
      this.$store.state.cart.input.country === 'CZE'
        ? 'cz'
        : this.$store.state.cart.input.country === 'SVK'
          ? 'sk'
          : false
    const initialCountry = this.countries.find(
      country =>
        selectedCountry ? country.code === selectedCountry : this.phoneNumber.includes(`+${country.dialCode}`),
    )

    if (initialCountry) {
      this.selectedCountry = initialCountry
    } else {
      this.selectedCountry = this.defaultCountry
    }
  },
  data() {
    const language = this.$i18n.locale
    let highlightedCountryCodes = []
    if (language === 'cs' || language === 'sk') {
      highlightedCountryCodes = ['cz', 'sk']
    }

    return {
      countries: [
        { code: 'af', name: 'Afghanistan (‫افغانستان‬‎)', dialCode: 93, example: '070 123 4567' },
        { code: 'al', name: 'Albania (Shqipëri)', dialCode: 355, example: '066 123 4567' },
        { code: 'dz', name: 'Algeria (‫الجزائر‬‎)', dialCode: 213, example: '0551 23 45 67' },
        { code: 'as', name: 'American Samoa', dialCode: 1684, example: '(684) 733-1234' },
        { code: 'ad', name: 'Andorra', dialCode: 376, example: '312 345' },
        { code: 'ao', name: 'Angola', dialCode: 244, example: '923 123 456' },
        { code: 'ai', name: 'Anguilla', dialCode: 1264, example: '(264) 235-1234' },
        { code: 'ag', name: 'Antigua and Barbuda', dialCode: 1268, example: '(268) 464-1234' },
        { code: 'ar', name: 'Argentina', dialCode: 54, example: '011 15-2345-6789' },
        { code: 'am', name: 'Armenia (Հայաստան)', dialCode: 374, example: '077 123456' },
        { code: 'aw', name: 'Aruba', dialCode: 297, example: '560 1234' },
        { code: 'au', name: 'Australia', dialCode: 61, example: '0412 345 678' },
        { code: 'at', name: 'Austria (Österreich)', dialCode: 43, example: '0664 123456' },
        { code: 'az', name: 'Azerbaijan (Azərbaycan)', dialCode: 994, example: '040 123 45 67' },
        { code: 'bs', name: 'Bahamas', dialCode: 1242, example: '(242) 359-1234' },
        { code: 'bh', name: 'Bahrain (‫البحرين‬‎)', dialCode: 973, example: '3600 1234' },
        { code: 'bd', name: 'Bangladesh (বাংলাদেশ)', dialCode: 880, example: '01812-345678' },
        { code: 'bb', name: 'Barbados', dialCode: 1246, example: '(246) 250-1234' },
        { code: 'by', name: 'Belarus (Беларусь)', dialCode: 375, example: '8 029 491-19-11' },
        { code: 'be', name: 'Belgium (België)', dialCode: 32, example: '0470 12 34 56' },
        { code: 'bz', name: 'Belize', dialCode: 501, example: '622-1234' },
        { code: 'bj', name: 'Benin (Bénin)', dialCode: 229, example: '90 01 12 34' },
        { code: 'bm', name: 'Bermuda', dialCode: 1441, example: '(441) 370-1234' },
        { code: 'bt', name: 'Bhutan (འབྲུག)', dialCode: 975, example: '17 12 34 56' },
        { code: 'bo', name: 'Bolivia', dialCode: 591, example: '71234567' },
        { code: 'ba', name: 'Bosnia and Herzegovina (Босна и Херцеговина)', dialCode: 387, example: '061 123 456' },
        { code: 'bw', name: 'Botswana', dialCode: 267, example: '71 123 456' },
        { code: 'br', name: 'Brazil (Brasil)', dialCode: 55, example: '(11) 96123-4567' },
        { code: 'io', name: 'British Indian Ocean Territory', dialCode: 246, example: '380 1234' },
        { code: 'vg', name: 'British Virgin Islands', dialCode: 1284, example: '(284) 300-1234' },
        { code: 'bn', name: 'Brunei', dialCode: 673, example: '712 3456' },
        { code: 'bg', name: 'Bulgaria (България)', dialCode: 359, example: '048 123 456' },
        { code: 'bf', name: 'Burkina Faso', dialCode: 226, example: '70 12 34 56' },
        { code: 'bi', name: 'Burundi (Uburundi)', dialCode: 257, example: '79 56 12 34' },
        { code: 'kh', name: 'Cambodia (កម្ពុជា)', dialCode: 855, example: '091 234 567' },
        { code: 'cm', name: 'Cameroon (Cameroun)', dialCode: 237, example: '6 71 23 45 67' },
        { code: 'ca', name: 'Canada', dialCode: 1, example: '(204) 234-5678' },
        { code: 'cv', name: 'Cape Verde (Kabu Verdi)', dialCode: 238, example: '991 12 34' },
        { code: 'bq', name: 'Caribbean Netherlands', dialCode: 599, example: '318 1234' },
        { code: 'ky', name: 'Cayman Islands', dialCode: 1345, example: '(345) 323-1234' },
        {
          code: 'cf',
          name: 'Central African Republic (République centrafricaine)',
          dialCode: 236,
          example: '70 01 23 45',
        },
        { code: 'td', name: 'Chad (Tchad)', dialCode: 235, example: '63 01 23 45' },
        { code: 'cl', name: 'Chile', dialCode: 56, example: '09 6123 4567' },
        { code: 'cn', name: 'China (中国)', dialCode: 86, example: '131 2345 6789' },
        { code: 'cx', name: 'Christmas Island', dialCode: 61, example: '0412 345 678' },
        { code: 'cc', name: 'Cocos (Keeling) Islands', dialCode: 61, example: '0412 345 678' },
        { code: 'co', name: 'Colombia', dialCode: 57, example: '321 1234567' },
        { code: 'km', name: 'Comoros (‫جزر القمر‬‎)', dialCode: 269, example: '321 23 45' },
        {
          code: 'cd',
          name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
          dialCode: 243,
          example: '0991 234 567',
        },
        { code: 'cg', name: 'Congo (Republic) (Congo-Brazzaville)', dialCode: 242, example: '06 123 4567' },
        { code: 'ck', name: 'Cook Islands', dialCode: 682, example: '71 234' },
        { code: 'cr', name: 'Costa Rica', dialCode: 506, example: '8312 3456' },
        { code: 'ci', name: 'Côte d’Ivoire', dialCode: 225, example: '01 23 45 67' },
        { code: 'hr', name: 'Croatia (Hrvatska)', dialCode: 385, example: '091 234 5678' },
        { code: 'cu', name: 'Cuba', dialCode: 53, example: '05 1234567' },
        { code: 'cw', name: 'Curaçao', dialCode: 599, example: '9 518 1234' },
        { code: 'cy', name: 'Cyprus (Κύπρος)', dialCode: 357, example: '96 123456' },
        { code: 'cz', name: 'Česká republika (Czech Republic)', dialCode: 420, example: '601 123 456' },
        { code: 'dk', name: 'Denmark (Danmark)', dialCode: 45, example: '20 12 34 56' },
        { code: 'dj', name: 'Djibouti', dialCode: 253, example: '77 83 10 01' },
        { code: 'dm', name: 'Dominica', dialCode: 1767, example: '(767) 225-1234' },
        { code: 'do', name: 'Dominican Republic (República Dominicana)', dialCode: 1, example: '(809) 234-5678' },
        { code: 'ec', name: 'Ecuador', dialCode: 593, example: '099 123 4567' },
        { code: 'eg', name: 'Egypt (‫مصر‬‎)', dialCode: 20, example: '0100 123 4567' },
        { code: 'sv', name: 'El Salvador', dialCode: 503, example: '7012 3456' },
        { code: 'gq', name: 'Equatorial Guinea (Guinea Ecuatorial)', dialCode: 240, example: '222 123 456' },
        { code: 'er', name: 'Eritrea', dialCode: 291, example: '07 123 456' },
        { code: 'ee', name: 'Estonia (Eesti)', dialCode: 372, example: '5123 4567' },
        { code: 'et', name: 'Ethiopia', dialCode: 251, example: '091 123 4567' },
        { code: 'fk', name: 'Falkland Islands (Islas Malvinas)', dialCode: 500, example: '51234' },
        { code: 'fo', name: 'Faroe Islands (Føroyar)', dialCode: 298, example: '211234' },
        { code: 'fj', name: 'Fiji', dialCode: 679, example: '701 2345' },
        { code: 'fi', name: 'Finland (Suomi)', dialCode: 358, example: '041 2345678' },
        { code: 'fr', name: 'France', dialCode: 33, example: '06 12 34 56 78' },
        { code: 'gf', name: 'French Guiana (Guyane française)', dialCode: 594, example: '0694 20 12 34' },
        { code: 'pf', name: 'French Polynesia (Polynésie française)', dialCode: 689, example: '87 12 34 56' },
        { code: 'ga', name: 'Gabon', dialCode: 241, example: '06 03 12 34' },
        { code: 'gm', name: 'Gambia', dialCode: 220, example: '301 2345' },
        { code: 'ge', name: 'Georgia (საქართველო)', dialCode: 995, example: '555 12 34 56' },
        { code: 'de', name: 'Germany (Deutschland)', dialCode: 49, example: '01512 3456789' },
        { code: 'gh', name: 'Ghana (Gaana)', dialCode: 233, example: '023 123 4567' },
        { code: 'gi', name: 'Gibraltar', dialCode: 350, example: '57123456' },
        { code: 'gr', name: 'Greece (Ελλάδα)', dialCode: 30, example: '691 234 5678' },
        { code: 'gl', name: 'Greenland (Kalaallit Nunaat)', dialCode: 299, example: '22 12 34' },
        { code: 'gd', name: 'Grenada', dialCode: 1473, example: '(473) 403-1234' },
        { code: 'gp', name: 'Guadeloupe', dialCode: 590, example: '0690 30-1234' },
        { code: 'gu', name: 'Guam', dialCode: 1671, example: '(671) 300-1234' },
        { code: 'gt', name: 'Guatemala', dialCode: 502, example: '5123 4567' },
        { code: 'gg', name: 'Guernsey', dialCode: 44, example: '07781 123456' },
        { code: 'gn', name: 'Guinea (Guinée)', dialCode: 224, example: '601 12 34 56' },
        { code: 'gw', name: 'Guinea-Bissau (Guiné Bissau)', dialCode: 245, example: '955 012 345' },
        { code: 'gy', name: 'Guyana', dialCode: 592, example: '609 1234' },
        { code: 'ht', name: 'Haiti', dialCode: 509, example: '34 10 1234' },
        { code: 'hn', name: 'Honduras', dialCode: 504, example: '9123-4567' },
        { code: 'hk', name: 'Hong Kong (香港)', dialCode: 852, example: '5123 4567' },
        { code: 'hu', name: 'Hungary (Magyarország)', dialCode: 36, example: '(20) 123 4567' },
        { code: 'is', name: 'Iceland (Ísland)', dialCode: 354, example: '611 1234' },
        { code: 'in', name: 'India (भारत)', dialCode: 91, example: '099876 54321' },
        { code: 'id', name: 'Indonesia', dialCode: 62, example: '0812-345-678' },
        { code: 'ir', name: 'Iran (‫ایران‬‎)', dialCode: 98, example: '0912 345 6789' },
        { code: 'iq', name: 'Iraq (‫العراق‬‎)', dialCode: 964, example: '0791 234 5678' },
        { code: 'ie', name: 'Ireland', dialCode: 353, example: '085 012 3456' },
        { code: 'im', name: 'Isle of Man', dialCode: 44, example: '07924 123456' },
        { code: 'il', name: 'Israel (‫ישראל‬‎)', dialCode: 972, example: '050-123-4567' },
        { code: 'it', name: 'Italy (Italia)', dialCode: 39, example: '312 345 6789' },
        { code: 'jm', name: 'Jamaica', dialCode: 1876, example: '(876) 210-1234' },
        { code: 'jp', name: 'Japan (日本)', dialCode: 81, example: '090-1234-5678' },
        { code: 'je', name: 'Jersey', dialCode: 44, example: '07797 123456' },
        { code: 'jo', name: 'Jordan (‫الأردن‬‎)', dialCode: 962, example: '07 9012 3456' },
        { code: 'kz', name: 'Kazakhstan (Казахстан)', dialCode: 7, example: '8 (771) 000 9998' },
        { code: 'ke', name: 'Kenya', dialCode: 254, example: '0712 123456' },
        { code: 'ki', name: 'Kiribati', dialCode: 686, example: '72012345' },
        { code: 'xk', name: 'Kosovo', dialCode: 383, example: '' },
        { code: 'kw', name: 'Kuwait (‫الكويت‬‎)', dialCode: 965, example: '500 12345' },
        { code: 'kg', name: 'Kyrgyzstan (Кыргызстан)', dialCode: 996, example: '0700 123 456' },
        { code: 'la', name: 'Laos (ລາວ)', dialCode: 856, example: '020 23 123 456' },
        { code: 'lv', name: 'Latvia (Latvija)', dialCode: 371, example: '21 234 567' },
        { code: 'lb', name: 'Lebanon (‫لبنان‬‎)', dialCode: 961, example: '71 123 456' },
        { code: 'ls', name: 'Lesotho', dialCode: 266, example: '5012 3456' },
        { code: 'lr', name: 'Liberia', dialCode: 231, example: '077 012 3456' },
        { code: 'ly', name: 'Libya (‫ليبيا‬‎)', dialCode: 218, example: '091-2345678' },
        { code: 'li', name: 'Liechtenstein', dialCode: 423, example: '660 234 567' },
        { code: 'lt', name: 'Lithuania (Lietuva)', dialCode: 370, example: '(8-612) 34567' },
        { code: 'lu', name: 'Luxembourg', dialCode: 352, example: '628 123 456' },
        { code: 'mo', name: 'Macau (澳門)', dialCode: 853, example: '6612 3456' },
        { code: 'mk', name: 'Macedonia (FYROM) (Македонија)', dialCode: 389, example: '072 345 678' },
        { code: 'mg', name: 'Madagascar (Madagasikara)', dialCode: 261, example: '032 12 345 67' },
        { code: 'mw', name: 'Malawi', dialCode: 265, example: '0991 23 45 67' },
        { code: 'my', name: 'Malaysia', dialCode: 60, example: '012-345 6789' },
        { code: 'mv', name: 'Maldives', dialCode: 960, example: '771-2345' },
        { code: 'ml', name: 'Mali', dialCode: 223, example: '65 01 23 45' },
        { code: 'mt', name: 'Malta', dialCode: 356, example: '9696 1234' },
        { code: 'mh', name: 'Marshall Islands', dialCode: 692, example: '235-1234' },
        { code: 'mq', name: 'Martinique', dialCode: 596, example: '0696 20 12 34' },
        { code: 'mr', name: 'Mauritania (‫موريتانيا‬‎)', dialCode: 222, example: '22 12 34 56' },
        { code: 'mu', name: 'Mauritius (Moris)', dialCode: 230, example: '5251 2345' },
        { code: 'yt', name: 'Mayotte', dialCode: 262, example: '0639 12 34 56' },
        { code: 'mx', name: 'Mexico (México)', dialCode: 52, example: '044 222 123 4567' },
        { code: 'fm', name: 'Micronesia', dialCode: 691, example: '350 1234' },
        { code: 'md', name: 'Moldova (Republica Moldova)', dialCode: 373, example: '0621 12 345' },
        { code: 'mc', name: 'Monaco', dialCode: 377, example: '06 12 34 56 78' },
        { code: 'mn', name: 'Mongolia (Монгол)', dialCode: 976, example: '8812 3456' },
        { code: 'me', name: 'Montenegro (Crna Gora)', dialCode: 382, example: '067 622 901' },
        { code: 'ms', name: 'Montserrat', dialCode: 1664, example: '(664) 492-3456' },
        { code: 'ma', name: 'Morocco (‫المغرب‬‎)', dialCode: 212, example: '0650-123456' },
        { code: 'mz', name: 'Mozambique (Moçambique)', dialCode: 258, example: '82 123 4567' },
        { code: 'mm', name: 'Myanmar (Burma) (မြန်မာ)', dialCode: 95, example: '09 212 3456' },
        { code: 'na', name: 'Namibia (Namibië)', dialCode: 264, example: '081 123 4567' },
        { code: 'nr', name: 'Nauru', dialCode: 674, example: '555 1234' },
        { code: 'np', name: 'Nepal (नेपाल)', dialCode: 977, example: '984-1234567' },
        { code: 'nl', name: 'Netherlands (Nederland)', dialCode: 31, example: '06 12345678' },
        { code: 'nc', name: 'New Caledonia (Nouvelle-Calédonie)', dialCode: 687, example: '75.12.34' },
        { code: 'nz', name: 'New Zealand', dialCode: 64, example: '021 123 4567' },
        { code: 'ni', name: 'Nicaragua', dialCode: 505, example: '8123 4567' },
        { code: 'ne', name: 'Niger (Nijar)', dialCode: 227, example: '93 12 34 56' },
        { code: 'ng', name: 'Nigeria', dialCode: 234, example: '0802 123 4567' },
        { code: 'nu', name: 'Niue', dialCode: 683, example: '1234' },
        { code: 'nf', name: 'Norfolk Island', dialCode: 672, example: '3 81234' },
        { code: 'kp', name: 'North Korea (조선 민주주의 인민 공화국)', dialCode: 850, example: '0192 123 4567' },
        { code: 'mp', name: 'Northern Mariana Islands', dialCode: 1670, example: '(670) 234-5678' },
        { code: 'no', name: 'Norway (Norge)', dialCode: 47, example: '406 12 345' },
        { code: 'om', name: 'Oman (‫عُمان‬‎)', dialCode: 968, example: '9212 3456' },
        { code: 'pk', name: 'Pakistan (‫پاکستان‬‎)', dialCode: 92, example: '0301 2345678' },
        { code: 'pw', name: 'Palau', dialCode: 680, example: '620 1234' },
        { code: 'ps', name: 'Palestine (‫فلسطين‬‎)', dialCode: 970, example: '0599 123 456' },
        { code: 'pa', name: 'Panama (Panamá)', dialCode: 507, example: '6001-2345' },
        { code: 'pg', name: 'Papua New Guinea', dialCode: 675, example: '681 2345' },
        { code: 'py', name: 'Paraguay', dialCode: 595, example: '0961 456789' },
        { code: 'pe', name: 'Peru (Perú)', dialCode: 51, example: '912 345 678' },
        { code: 'ph', name: 'Philippines', dialCode: 63, example: '0905 123 4567' },
        { code: 'pl', name: 'Poland (Polska)', dialCode: 48, example: '512 345 678' },
        { code: 'pt', name: 'Portugal', dialCode: 351, example: '912 345 678' },
        { code: 'pr', name: 'Puerto Rico', dialCode: 1, example: '(787) 234-5678' },
        { code: 'qa', name: 'Qatar (‫قطر‬‎)', dialCode: 974, example: '3312 3456' },
        { code: 're', name: 'Réunion (La Réunion)', dialCode: 262, example: '0692 12 34 56' },
        { code: 'ro', name: 'Romania (România)', dialCode: 40, example: '0712 345 678' },
        { code: 'ru', name: 'Russia (Россия)', dialCode: 7, example: '8 (912) 345-67-89' },
        { code: 'rw', name: 'Rwanda', dialCode: 250, example: '0720 123 456' },
        { code: 'bl', name: 'Saint Barthélemy (Saint-Barthélemy)', dialCode: 590, example: '0690 30-1234' },
        { code: 'sh', name: 'Saint Helena', dialCode: 290, example: '51234' },
        { code: 'kn', name: 'Saint Kitts and Nevis', dialCode: 1869, example: '(869) 765-2917' },
        { code: 'lc', name: 'Saint Lucia', dialCode: 1758, example: '(758) 284-5678' },
        {
          code: 'mf',
          name: 'Saint Martin (Saint-Martin (partie française))',
          dialCode: 590,
          example: '0690 30-1234',
        },
        {
          code: 'pm',
          name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
          dialCode: 508,
          example: '055 12 34',
        },
        { code: 'vc', name: 'Saint Vincent and the Grenadines', dialCode: 1784, example: '(784) 430-1234' },
        { code: 'ws', name: 'Samoa', dialCode: 685, example: '601234' },
        { code: 'sm', name: 'San Marino', dialCode: 378, example: '66 66 12 12' },
        { code: 'st', name: 'São Tomé and Príncipe (São Tomé e Príncipe)', dialCode: 239, example: '981 2345' },
        { code: 'sa', name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)', dialCode: 966, example: '051 234 5678' },
        { code: 'sn', name: 'Senegal (Sénégal)', dialCode: 221, example: '70 123 45 67' },
        { code: 'rs', name: 'Serbia (Србија)', dialCode: 381, example: '060 1234567' },
        { code: 'sc', name: 'Seychelles', dialCode: 248, example: '2 510 123' },
        { code: 'sl', name: 'Sierra Leone', dialCode: 232, example: '(025) 123456' },
        { code: 'sg', name: 'Singapore', dialCode: 65, example: '8123 4567' },
        { code: 'sx', name: 'Sint Maarten', dialCode: 1721, example: '(721) 520-5678' },
        { code: 'sk', name: 'Slovensko (Slovakia)', dialCode: 421, example: '0912 123 456' },
        { code: 'si', name: 'Slovenia (Slovenija)', dialCode: 386, example: '031 234 567' },
        { code: 'sb', name: 'Solomon Islands', dialCode: 677, example: '74 21234' },
        { code: 'so', name: 'Somalia (Soomaaliya)', dialCode: 252, example: '7 1123456' },
        { code: 'za', name: 'South Africa', dialCode: 27, example: '071 123 4567' },
        { code: 'kr', name: 'South Korea (대한민국)', dialCode: 82, example: '010-0000-0000' },
        { code: 'ss', name: 'South Sudan (‫جنوب السودان‬‎)', dialCode: 211, example: '0977 123 456' },
        { code: 'es', name: 'Spain (España)', dialCode: 34, example: '612 34 56 78' },
        { code: 'lk', name: 'Sri Lanka (ශ්‍රී ලංකාව)', dialCode: 94, example: '071 234 5678' },
        { code: 'sd', name: 'Sudan (‫السودان‬‎)', dialCode: 249, example: '091 123 1234' },
        { code: 'sr', name: 'Suriname', dialCode: 597, example: '741-2345' },
        { code: 'sj', name: 'Svalbard and Jan Mayen', dialCode: 47, example: '412 34 567' },
        { code: 'sz', name: 'Swaziland', dialCode: 268, example: '7612 3456' },
        { code: 'se', name: 'Sweden (Sverige)', dialCode: 46, example: '070-123 45 67' },
        { code: 'ch', name: 'Switzerland (Schweiz)', dialCode: 41, example: '078 123 45 67' },
        { code: 'sy', name: 'Syria (‫سوريا‬‎)', dialCode: 963, example: '0944 567 890' },
        { code: 'tw', name: 'Taiwan (台灣)', dialCode: 886, example: '0912 345 678' },
        { code: 'tj', name: 'Tajikistan', dialCode: 992, example: '(8) 917 12 3456' },
        { code: 'tz', name: 'Tanzania', dialCode: 255, example: '0621 234 567' },
        { code: 'th', name: 'Thailand (ไทย)', dialCode: 66, example: '081 234 5678' },
        { code: 'tl', name: 'Timor-Leste', dialCode: 670, example: '7721 2345' },
        { code: 'tg', name: 'Togo', dialCode: 228, example: '90 11 23 45' },
        { code: 'tk', name: 'Tokelau', dialCode: 690, example: '7290' },
        { code: 'to', name: 'Tonga', dialCode: 676, example: '771 5123' },
        { code: 'tt', name: 'Trinidad and Tobago', dialCode: 1868, example: '(868) 291-1234' },
        { code: 'tn', name: 'Tunisia (‫تونس‬‎)', dialCode: 216, example: '20 123 456' },
        { code: 'tr', name: 'Turkey (Türkiye)', dialCode: 90, example: '0501 234 56 78' },
        { code: 'tm', name: 'Turkmenistan', dialCode: 993, example: '8 66 123456' },
        { code: 'tc', name: 'Turks and Caicos Islands', dialCode: 1649, example: '(649) 231-1234' },
        { code: 'tv', name: 'Tuvalu', dialCode: 688, example: '901234' },
        { code: 'us', name: 'United States', dialCode: 1, example: '(201) 555-0123' },
        { code: 'gb', name: 'United Kingdom', dialCode: 44, example: '07400 123456' },
        { code: 'vi', name: 'U.S. Virgin Islands', dialCode: 1340, example: '(340) 642-1234' },
        { code: 'ug', name: 'Uganda', dialCode: 256, example: '0712 345678' },
        { code: 'ua', name: 'Ukraine (Україна)', dialCode: 380, example: '039 123 4567' },
        {
          code: 'ae',
          name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
          dialCode: 971,
          example: '050 123 4567',
        },
        { code: 'uy', name: 'Uruguay', dialCode: 598, example: '094 231 234' },
        { code: 'uz', name: 'Uzbekistan (Oʻzbekiston)', dialCode: 998, example: '8 91 234 56 78' },
        { code: 'vu', name: 'Vanuatu', dialCode: 678, example: '591 2345' },
        { code: 'va', name: 'Vatican City (Città del Vaticano)', dialCode: 39, example: '312 345 6789' },
        { code: 've', name: 'Venezuela', dialCode: 58, example: '0412-1234567' },
        { code: 'vn', name: 'Vietnam (Việt Nam)', dialCode: 84, example: '091 234 56 78' },
        { code: 'wf', name: 'Wallis and Futuna', dialCode: 681, example: '50 12 34' },
        { code: 'eh', name: 'Western Sahara (‫الصحراء الغربية‬‎)', dialCode: 212, example: '0650-123456' },
        { code: 'ye', name: 'Yemen (‫اليمن‬‎)', dialCode: 967, example: '0712 345 678' },
        { code: 'zm', name: 'Zambia', dialCode: 260, example: '095 5123456' },
        { code: 'zw', name: 'Zimbabwe', dialCode: 263, example: '071 123 4567' },
        { code: 'ax', name: 'Åland Islands', dialCode: 358, example: '041 2345678' },
      ],
      highlightedCountryCodes,
      phoneNumber: this.value,
      selectedCountry: null,
    }
  },
  computed: {
    highlightedCountries() {
      return this.countries.filter(country => this.highlightedCountryCodes.includes(country.code))
    },
    defaultCountry() {
      let countryCode = this.$store.state.globals.countries.find(
        country =>
          country.languageCode ===
          this.$store.state.globals.languages.find(language => language.code === this.$i18n.locale).code,
      )
      const defaultCountryCode = _.get(
        this.$store,
        'state.cart.cartData.shippingAddress.countryAlpha2',
        countryCode ? countryCode.alpha2 : 'cz',
      ).toLowerCase()
      return this.countries.find(country => country.code === defaultCountryCode)
    },
    required() {
      return this.validation.includes('required')
    },
  },
  watch: {
    selectedCountry(newValue, oldValue) {
      if (!this.phoneNumber) {
        if (this.required) {
          this.phoneNumber = `+${newValue.dialCode} `
        }
      } else if (
        oldValue &&
        this.phoneNumber.includes(`+${oldValue.dialCode}`) &&
        !this.phoneNumber.includes(`+${newValue.dialCode}`)
      ) {
        this.phoneNumber = this.phoneNumber.replace(`+${oldValue.dialCode}`, `+${newValue.dialCode}`)
      } else if (!this.phoneNumber.includes('+')) {
        this.phoneNumber = `+${newValue.dialCode} ${this.phoneNumber}`
      } else if (!this.phoneNumber.includes(`+${newValue.dialCode}`)) {
        this.phoneNumber = `+${newValue.dialCode}`
      }
    },
    phoneNumber(value) {
      if (!this.required && value.length === 0) {
        this.phoneNumber = ''
      } else if (!value.startsWith('+') && this.selectedCountry) {
        this.phoneNumber = `+${this.selectedCountry.dialCode} ${value}`
      }
      if (value.startsWith('+')) {
        const countriesFiltered = this.countries
          .filter(country => value.startsWith(`+${country.dialCode}`))
          .sort((a, b) => {
            return b.dialCode.length - a.dialCode.length
          })
        if (
          countriesFiltered.length > 0 &&
          (!this.selectedCountry || this.selectedCountry.dialCode !== countriesFiltered[0].dialCode)
        ) {
          this.selectedCountry = countriesFiltered[0]
        }
      }
      this.$emit('input', this.phoneNumber)
    },
    value() {
      this.phoneNumber = this.value
    },
  },
}
