<script>
import FoxentryInputsParentMixin from '~/mixins/FoxentryInputsParentMixin'

export default {
  mixins: [FoxentryInputsParentMixin],
  computed: {
    errorMessage() {
      if (this.errors && !!this.errors.items.length) {
        const error = this.errors.items.find(i => i.field === this.name)
        if (error) {
          return error.msg
        }
      }

      return null
    },
  },
}
</script>
